import React from 'react';
import { graphql } from 'gatsby';
import { instanceOf } from 'prop-types';
import { Grid } from '@material-ui/core';
import Layout from '../../components/layout';
import ArticleGrid from '../../components/template-parts/ArticleGrid';
import returnPaginatedData from '../../utils/dataPagination';
import '../../assets/app.css';

const HomePage = ({ data: { wpUser }, pageContext }) => {
  const nodes = returnPaginatedData(
    wpUser.posts.nodes,
    pageContext.authorPostsPerPage, pageContext.page,
  );
  return (
    <Layout>
      <Grid container justify="flex-start" alignItems="flex-start">
        <Grid className="authorPostContainer" item xs={12}>
          <ArticleGrid
            listTitle={pageContext.pageName}
            hidePageTitle
            route="author"
            posts={nodes}
            pageContext={pageContext}
            paginate
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

HomePage.propTypes = {
  data: instanceOf(Object).isRequired,
  pageContext: instanceOf(Object).isRequired,
};

export default HomePage;

export const query = graphql`
    fragment Thumbnail on File {
        childImageSharp {
            fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_tracedSVG
            }
        }
    }

    query AuthorPosts($authorID: String!) {
        wpUser(id: { eq: $authorID }) {
            posts {
                nodes {
                    uri
                    title
                    date,
                    featuredImage {
                        node {
                            localFile {
                                ...Thumbnail
                            }
                        }
                    }
                }
            }
        }
    }
`;
